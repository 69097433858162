import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide {...props} direction="up" ref={ref} />;
});

export default function ErrorAlert({ 
  open,
  onClose, 
  onRetry, 
  errorMessage = "משהו השתבש בחיבור, בוא ננסה להביא את המידע הראשי שוב 😀"
}) {
  const handleClose = (event, reason) => {
    // Prevent closing on backdrop click and escape key
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    // Allow closing in other cases
    if (onClose) {
      onClose(event, reason);
    }
  };

  const handleRetry = () => {
    if (onRetry) {
      onRetry();
    }
    // Close the dialog after retry
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="error-alert-slide-description"
      ModalProps={{
        disableEscapeKeyDown: true,
      }}
    >
      <DialogTitle>{"אוייש"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-alert-slide-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Button onClick={handleRetry} variant="contained" color="primary">
          נסה שוב
        </Button>
      </DialogActions>
    </Dialog>
  );
}