import React, { useState } from "react";
import "./SignIn.css";
import useApi from './useApi';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import VersatileAlertDialog from "./UI/VersatileAlertDialog";
import LogRocket from 'logrocket';

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [versatileAlertOpen, setVersatileAlertOpen] = useState(false);


  const { useSignIn } = useApi();
  const signIn = useSignIn();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signIn(email, password);
      setLoading(false);
      console.log("Sign in returned:", response);
      if (!response.hasSubscription) {
        setAlertMessage("נראה שאינך מנוי או ביטלת את המנוי שלך. נא לחדש את המנוי תודה :)");
        setAlertVisible(true);
      } else {
        LogRocket.identify(response.id, {
          name: response.displayName,
          email: response.email,
        });
      }
    } catch (error) {
      console.error("Sign in failed:", error);
      setVersatileAlertOpen(true)
    } finally {
      setLoading(false)
    }
  };

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const handleVersatileAlertClose = () => {
    setVersatileAlertOpen(false);
  };

  return (
    <div className="full-height-container">
      <div className="signin-container">
        <h1>LawLoom</h1>
        <p>עריכת דין של המאה ה-21</p>
        <form onSubmit={handleSignIn} className="form-container">
          <input
            type="email"
            value={email}
            placeholder="אימייל"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            value={password}
            placeholder="סיסמה"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div>
            <input
              type="checkbox"
              id="rememberMeCheckbox"
              checked={rememberMe}
              onChange={toggleRememberMe}
            />
            <label className="checkbox-label" htmlFor="rememberMeCheckbox">
              זכור אותי
            </label>
            {/* Add link or onClick event for Forgot Password */}
            <a className="forgot-password-link" href="/forgot-password">
              שכחתי סיסמה
            </a>
          </div>

          <button type="submit" disabled={loading}>
            Login
          </button>
          {loading && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12, // Half of loader's height
                marginLeft: -12, // Half of loader's width
              }}
            />
          )}
          {alertVisible && (
            <Alert
              style={{
                marginTop: 12,
              }}
              severity="error"
              onClose={() => setAlertVisible(false)}
            >
              {alertMessage}
            </Alert>
          )}
        </form>
      </div>
      <VersatileAlertDialog
        open={versatileAlertOpen}
        onClose={handleVersatileAlertClose}
        onTryAgain={handleVersatileAlertClose}
      />
    </div>
  );
};

export default SignIn;
