import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import theme from './theme';
import { ThemeProvider, CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { AuthProvider, useAuth } from './AuthContext';
import SignUp from './Signup';

function App() {
  const { isFullyAuthenticated } = useAuth();

  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin, prefixer],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
          <Route path="/" element={!isFullyAuthenticated() ? <Navigate replace to="/signup" /> : <Navigate replace to="/dashboard" />} />
            <Route path="/signup" element={!isFullyAuthenticated() ? <SignUp /> : <Navigate replace to="/dashboard" />} />
            <Route path="/signin" element={!isFullyAuthenticated() ? <SignIn /> : <Navigate replace to="/dashboard" />} />
            <Route path="/dashboard/*" element={isFullyAuthenticated() ? <Dashboard /> : <Navigate replace to="/signin" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );
}

const AppWithAuth = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWithAuth;
