import React, { createContext, useContext, useState, useEffect } from 'react';

import { useConditionalNavigation } from '../Common/CondionalNavigationProvider';

export const FormContext = createContext(null);

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userFiles, setUserFiles] = useState(null);

  const [govFileNames, setGovFileNames] = useState([]);

  const { setUnsavedChanges } = useConditionalNavigation();

  useEffect(() => {
    checkForUnsavedChanges();
  }, [selectedFiles]); 

  const checkForUnsavedChanges = () => {
    if (selectedFiles && selectedFiles.length > 0) {
      setUnsavedChanges(true);
    }
  };

  const updateFormData = (newData) => {
    try {
      setFormData((prevData) => {
        return { ...prevData, ...newData };
      });
    } catch (error) {
      console.error("updateFormData ", error);
    }
  };

  const updateCurrentType = (newType) => {
    setCurrentType(newType);
  };

  const updateSelectedFiles = (newSelectedFiles) => {
    setSelectedFiles(newSelectedFiles);
  };

  const updateUserFiles = (newSelectedFiles) => {
    setUserFiles(newSelectedFiles);
  };

  const formDataArray = () => {
    return formData ? Object.values(formData) : [];
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        currentType,
        updateFormData,
        updateCurrentType,
        formDataArray,
        selectedFiles,
        updateSelectedFiles,
        userFiles,
        updateUserFiles,
        govFileNames, 
        setGovFileNames
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);