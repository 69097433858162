import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import FormSelector from "./FormSelector";
import GenericForm from "./GenericForm";
import { useForm } from "./FormContext";
import { useContracts } from '../Docx/ContractsContext';
import ErrorAlert from "../UI/ErrorAlert";

const FormContainer = () => {
  const location = useLocation();
  const { updateFormData, formData, selectedFiles } = useForm();
  const { contracts, govContracts } = useContracts();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  useEffect(() => {
    const editJsonMode = location.state ? location.state.deal.json : null;
    buildDynamicFormData(editJsonMode);
  }, [selectedFiles]);

  const buildDynamicFormData = async (editJsonMode) => {
    try {
      let response;
      if (!editJsonMode) {
        const filteredContracts = contracts.filter((contract) =>
          selectedFiles.includes(contract.name)
        );
        const filteredGovContracts = govContracts.filter((contract) =>
          selectedFiles.includes(contract.name)
        );
        const combinedContracts = [
          ...filteredContracts,
          ...filteredGovContracts,
        ];

        const responseForm = buildCompleteJsonStructure(combinedContracts);
        response = responseForm;
      } else {
        response = editJsonMode;
      }
      console.log("buildDynamicFormData successful:", response);
      updateFormData(response);
    } catch (error) {
      console.error("buildDynamicFormData failed:", error);
      setErrorDialogOpen(true);
    }
  };

  const initialJsonStructure = [
    {
      type: "Seller",
      title: "מוכר",
      allowMultiple: true,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "Property data",
      title: "פרטי הנכס",
      allowMultiple: false,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "Buyer",
      title: "קונה",
      allowMultiple: true,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "DealInfo",
      title: "פרטי העיסקה",
      allowMultiple: true,
      inputs: [],
      inputsMap: {},
    },
    {
      type: "Miscellaneous",
      title: "שונות",
      allowMultiple: false,
      inputs: [],
      inputsMap: {},
    },
  ];

  const mapDictionaryToJSON = (dictionary, jsonStructure) => {
    return jsonStructure.map((category) => {
      const categoryType = category.type;
      const inputsMap = category.inputsMap || {};

      Object.keys(dictionary.payload).forEach((key) => {
        if (categorizeKey(dictionary.payload[key]) === categoryType) {
          const label = dictionary.payload[key].replace(/_/g, " ");
          if (!inputsMap[label]) {
            inputsMap[label] = {
              type: "TextInput",
              label: label,
              placeholder: dictionary.payload[key],
            };
          }
        }
      });

      return {
        ...category,
        inputsMap: inputsMap,
        inputs: Object.values(inputsMap),
      };
    });
  };

  // Sets for O(1) lookup
  const buyerKeywords = new Set(["קונה"]);
  const sellerKeywords = new Set(["מוכר"]);
  const propertyDataKeywords = new Set([
    "כתובת",
    "מקרקעין",
    "גוש",
    "חלקה",
    "תת-חלקה",
    "קומה",
    "חדר",
    "חדרים",
    "שטח",
    "דירה",
    "בדירה",
    "מחסן",
  ]);
  const dealInfo = new Set([
    "התמורה",
    "תמורה",
    "התשלום",
    "תשלום",
    "מסירה",
    "המסירה",
  ]);

  const isBuyer = (tokens) => {
    for (const token of tokens) {
      if (buyerKeywords.has(token)) {
        return true;
      }
    }
    return false;
  };

  const isSeller = (tokens) => {
    for (const token of tokens) {
      if (sellerKeywords.has(token)) {
        return true;
      }
    }
    return false;
  };

  const isPropertyData = (tokens) => {
    for (const token of tokens) {
      if (propertyDataKeywords.has(token)) {
        return true;
      }
    }
    return false;
  };

  const isDealInfo = (tokens) => {
    for (const token of tokens) {
      if (dealInfo.has(token)) {
        return true;
      }
    }
    return false;
  };

  const categorizeKey = (key) => {
    const tokens = key.split("_");

    if (isBuyer(tokens)) {
      return "Buyer";
    } else if (isSeller(tokens)) {
      return "Seller";
    } else if (isPropertyData(tokens)) {
      return "Property data";
    } else if (isDealInfo(tokens)) {
      return "DealInfo"
    } else {
      return "Miscellaneous";
    }
  };

  const buildCompleteJsonStructure = (dictionaries) => {
    return dictionaries.reduce((acc, dictionary) => {
      return mapDictionaryToJSON(dictionary, acc);
    }, initialJsonStructure);
  };

  const onClose = () => {
    setErrorDialogOpen(false);
  };

  const onRetryFetchData = async () => {
    setErrorDialogOpen(false);
    await buildDynamicFormData(null);
  };

  if (!formData) return <div>Loading...</div>;

  return (
    <div>
      <ErrorAlert
        open={errorDialogOpen}
        onClose={onClose}
        onRetry={onRetryFetchData}
      />
      <FormSelector />
      <GenericForm />
    </div>
  );
};

export default FormContainer;
