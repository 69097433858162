import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Grid } from '@mui/material';

import FileSelectionTab from './FileSelectionTab';
import FormContainer from './Forms/FormContainer';
import FormSummaryGeneration from './Forms/FormSummarizeGeneration';
import ConfirmLeaveDialog from './UI/ConfirmLeaveDialog';
import { useConditionalNavigation } from './Common/CondionalNavigationProvider';
import { useForm } from './Forms/FormContext';
import Alert from '@mui/material/Alert';
import './FormsGenerator.css'

const FormsGenerator = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { isDialogOpen, handleConfirm, handleCancel, setUnsavedChanges } = useConditionalNavigation()
  const { selectedFiles } = useForm();
  const [alertVisible, setAlertVisible] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    // Cleanup function that will be called when the component unmounts
    return () => {
      setUnsavedChanges(false);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    // Prevent navigation to tabs 1 or 2 if selectedFiles is empty
    if (selectedFiles.length === 0 && (newValue === 1 || newValue === 2)) {
      setAlertVisible(true)
      return; // Do not update the selectedTab state
    }
    setAlertVisible(false)
    setSelectedTab(newValue);
  };

  const handleClose = () => {
    setIsExiting(true); // Start exit animation
    setTimeout(() => {
      setAlertVisible(false); // Remove the alert after animation completes
      setIsExiting(false); // Reset exit state for future use
    }, 300); // Match the timeout to the CSS animation duration
  };

  return (
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="בחירת קבצים" />
        <Tab label="מילוי פרטי העיסקה" />
        <Tab label="סיכום" />
      </Tabs>
      <div style={{ display: selectedTab === 0 ? "block" : "none" }}>
        <FileSelectionTab />
      </div>
      <div style={{ display: selectedTab === 1 ? "block" : "none" }}>
        <FormContainer />
      </div>
      <div style={{ display: selectedTab === 2 ? "block" : "none" }}>
        <FormSummaryGeneration isVisible={selectedTab === 2} />
      </div>
      <ConfirmLeaveDialog
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      {alertVisible && (
        <Alert
          className={`alert-container ${
            isExiting ? "alert-exit" : "alert-enter"
          }`}
          variant="filled"
          severity="info"
          onClose={handleClose}
        >
          {"יש לבחור קבצים תחילה 🤖"}
        </Alert>
      )}
    </div>
  );
};

export default FormsGenerator;
