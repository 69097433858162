import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';

function EditTemplateDialog({
  editDialogOpen,
  handleEditDialogClose,
  editTemplate,
  setEditTemplate,
  handleEditSave,
}) {
  const handleChange = (e) => {
    const value = e.target.value;
    // Regular expression to replace any separator with an underscore
    const validValue = value.replace(/[\s.\\/]+/g, '_');
    setEditTemplate(validValue);
  };

  return (
    <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
      <DialogTitle>ערוך בחירה</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ערוך את התבנית עבור הטקסט שנבחר:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="תבנית"
          type="text"
          fullWidth
          value={editTemplate}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditDialogClose} color="secondary">
          ביטול
        </Button>
        <Button onClick={handleEditSave} color="primary">
          שמור
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditTemplateDialog;
