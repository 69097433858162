import React, { useState, useEffect } from 'react';
import { RingLoader } from 'react-spinners';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './LoaderComponent.css'; // Import the CSS file

export const LoadingState = {
  SCANNING: 'SCANNING',
  UPLOADING: 'UPLOADING',
  GENERAL: 'GENERAL',
};

const LoadingStates = {
  [LoadingState.SCANNING]: {
    messages: [
      "סורק מסמך...",
      "מנתח טקסט...",
      "מזהה הקשרים ותוכן...",
      "מייצר תוצאות...",
    ],
    interval: 3500,
  },
  [LoadingState.UPLOADING]: {
    messages: [
      "מעלה קבצים...",
      "בודק תאימות...",
      "מאמת מסמכים...",
    ],
    interval: 1000,
  },
  [LoadingState.GENERAL]: {
    messages: [
      "טוען נתונים...",
      "מתחבר לשרת...",
      "מבצע בקשת נתונים...",
      "הטענה הושלמה...",
    ],
    interval: 1000,
  },
};

const LoaderComponent = ({ loading, loadingState = LoadingState.GENERAL }) => {
  const { messages, interval } = LoadingStates[loadingState] || LoadingStates[LoadingState.GENERAL];
  const [currentMessage, setCurrentMessage] = useState(messages[0]);
  const [fadeClass, setFadeClass] = useState('fade-in');
  const [messageIndex, setMessageIndex] = useState(0);


  useEffect(() => {
    if (loading) {
      setMessageIndex(0);
      setCurrentMessage(messages[0]);
    }
  }, [loading, loadingState]);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      if (messageIndex < messages.length - 1) {
        setFadeClass('fade-out');
        setTimeout(() => {
          setCurrentMessage(messages[messageIndex + 1]);
          setFadeClass('fade-in');
          setMessageIndex(prevIndex => prevIndex + 1);
        }, 1000); // Match this timeout with the duration of the fade-out animation
      } else {
        clearInterval(messageInterval);
      }
    }, interval);

    return () => clearInterval(messageInterval);
  }, [messageIndex, messages, interval]);

  if (!loading) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bgcolor="rgba(255, 255, 255, 0.8)"
      zIndex="9999"
      className="fade-in"
    >
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginBottom={1.5}>
        {loadingState === LoadingState.SCANNING && (
          <Typography variant="h6" className="gradient-text" style={{ margin: '0 7px' }}>
            AI
          </Typography>
        )}
        <Typography variant="h6" className={`message ${fadeClass}`}>
          {currentMessage}
        </Typography>
      </Box>
      <RingLoader size={30} color={"#123abc"} loading={true} />
    </Box>
  );
};

export default LoaderComponent;
