import React, { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CssBaseline,
  styled,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import DealsView from "./DealsView";
import FormsGenerator from "./FormsGenerator";
import { DealsProvider } from "./DealsContext";
import {
  ConditionalNavigationProvider,
  useConditionalNavigation,
} from "./Common/CondionalNavigationProvider";
import ContractsManagerViewer from "./Docx/ContractsManagerViewer";
import { ContractsProvider } from "./Docx/ContractsContext";
import FeedbackButton from "./UI/FeedbackButton";
import { FormProvider } from './Forms/FormContext';

import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import theme from './theme';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin, prefixer], // Correct order
});

// Import or define your page components here
const Profile = () => <Box>Profile Content</Box>;

const drawerWidth = 240;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: "10px",
  marginTop: "4px",
  color: theme.palette.drawerText.main,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.common.white,
  },
}));

const DashboardComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasUnsavedChanges, confirmLeave } = useConditionalNavigation();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleNavigation = (path) => {
    if (hasUnsavedChanges) {
      confirmLeave(() => navigate(`/dashboard/${path}`));
    } else {
      navigate(`/dashboard/${path}`);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevOpen) => !prevOpen);
  };

  const handleAvatarClick = () => {
    navigate("/dashboard/profile");
  };

  const getListItemStyles = (path) => {
    const selected =
      (path === "" && location.pathname === "/dashboard") ||
      location.pathname === `/dashboard/${path}`;

    if (selected) {
      return {
        bgcolor: "primary.main",
        "&:hover": {
          bgcolor: "primary.main",
        },
        ".MuiListItemText-root": {
          color: "common.white",
        },
      };
    }
    return {};
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          {/* AppBar for user profile */}
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundImage: "linear-gradient(to left, #1C1C1E, #414148)",
            }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1 }}>
                LawLoomAI
              </Typography>
              <IconButton onClick={handleAvatarClick}>
                <Avatar src="/profile.jpg" alt="User Profile" />
              </IconButton>
            </Toolbar>
          </AppBar>

          {/* Drawer */}
          <Drawer
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
                marginTop: "64px",
                height: "96vh",
                overflowY: "auto",
                backgroundImage: "linear-gradient(to top, #1C1C1E, #414148)",
              },
            }}
          >
            <List sx={{ marginRight: "10px", marginLeft: "10px" }}>
              {[
                { text: "ניהול חוזים", path: "" },
                { text: "עסקאות", path: "reports" },
                { text: "יצירת עיסקה", path: "new-deal" },
                { text: "הגדרות משתמש", path: "profile" },
              ].map((item) => (
                <StyledListItem
                  button
                  component="div"
                  key={item.text}
                  onClick={() => handleNavigation(item.path)}
                  sx={getListItemStyles(item.path)}
                >
                  <ListItemText primary={item.text} />
                </StyledListItem>
              ))}
            </List>
          </Drawer>

          {/* Main content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 2,
              marginTop: "64px",
              marginLeft: drawerOpen ? "0px" : `-${drawerWidth}px`,
              transition: (theme) =>
                theme.transitions.create("margin", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
            }}
          >
            <Routes>
              <Route index element={<ContractsManagerViewer />} />
              <Route path="reports" element={<DealsView />} />
              <Route
                path="new-deal"
                element={
                  <FormProvider>
                    <FormsGenerator />
                  </FormProvider>
                }
              />
              <Route path="profile" element={<Profile />} />
            </Routes>
          </Box>
          <FeedbackButton />
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

const Dashboard = () => (
  <ConditionalNavigationProvider>
    <ContractsProvider>
      <DealsProvider>
        <DashboardComponent />
      </DealsProvider>
    </ContractsProvider>
  </ConditionalNavigationProvider>
);

export default Dashboard;
