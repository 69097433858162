import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConfirmLeaveDialog = ({ open, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>שינויים שלא נשמרו</DialogTitle>
      <DialogContent>
        <DialogContentText>
        שמנו לבד שיש לך שינויים שלא נשמרו, אתה בטוח שאתה רוצה להמשיך מבלי לשמור ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">לא</Button>
        <Button onClick={onConfirm} color="primary">כן</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmLeaveDialog;
