import React, { createContext, useContext, useState } from "react";
import useApi from "../useApi";

const ContractsContext = createContext();

export const ContractsProvider = ({ children }) => {
  const { useListContracts } = useApi();
  const listContracts = useListContracts();

  const [contracts, setContracts] = useState([]);
  const [govContracts, setGovContracts] = useState([]);

  const refreshContractsData = async () => {
    const {
      contracts: fetchedContracts,
      gov_metadata_files: fetchedGovContracts,
    } = await listContracts();

    // Sort contracts by date
    const sortedContracts = fetchedContracts.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const sortedGovContracts = fetchedGovContracts.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );

    setContracts(sortedContracts);
    setGovContracts(sortedGovContracts);
    console.log({ contracts, govContracts });
  };

  const fetchContracts = async (shouldForceUpdate = false) => {
    if (
      contracts.length === 0 ||
      govContracts.length === 0 ||
      shouldForceUpdate === true
    ) {
      await refreshContractsData();
    }
  };

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        govContracts,
        setContracts,
        setGovContracts,
        fetchContracts,
        refreshContractsData,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContracts = () => useContext(ContractsContext);
