import React from 'react';
import { Typography, Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';

const OutlinedContainer = ({ title, children }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "8px", // Spacing at the edges
        borderRadius: "8px",
        border: "1px solid #e0e0e0",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        marginBottom: "16px",
        width: "95%",
        backgroundColor: "#fafafa",
        transition: "box-shadow 0.2s ease",
        "&:hover": {
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      {title && (
        <Typography
          variant="h6"
          sx={{
            marginTop: "8px",
            marginLeft: '10px',
            fontWeight: 700,
            fontSize: "16px",
            color: "#333",
          }}
        >
          {title}
        </Typography>
      )}
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
          alignItems: "flex-start",
          "& > *": {
            margin: "8px", // Apply consistent margin directly to each child
          },
        }}
      >
        {children}
      </Stack>
    </Paper>
  );
};

OutlinedContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default OutlinedContainer;
