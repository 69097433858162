import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VersatileAlertDialog({
  open,
  onClose,
  onTryAgain,
  title = "אוייש",
  contentText = "הפעולה לא הצליחה, ננסה שוב ?",
  closeButtonText = 'חזור',
  tryAgainButtonText = 'נסה שוב',
}) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="versatile-alert-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="versatile-alert-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeButtonText}</Button>
        {onTryAgain && (
          <Button onClick={onTryAgain}>{tryAgainButtonText}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

VersatileAlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onTryAgain: PropTypes.func,
  title: PropTypes.string,
  contentText: PropTypes.string,
  closeButtonText: PropTypes.string,
  tryAgainButtonText: PropTypes.string,
};

export default VersatileAlertDialog;
