// useApi.js
import { useAuth } from './AuthContext';
import callApi, { EndPoint } from './networkService';

const useApi = () => {
  const { updateAuthState, updateSubscriptionState } = useAuth();

  const callApiWithAuth = async ({ endpoint, method = 'POST', body, includeCredentials = true }) => {
    try {
      const response = await callApi({ endpoint, method, body, includeCredentials });
    //    if (response.isAuthenticated) {
      // TODO:....
      updateAuthState(true);
      return response;

    } catch (error) {
      if (error.response && error.status === 401) {
        updateAuthState(false);
      }
      throw error;
    }
  };

  const retry = async (fn, retries = 5, delay = 2000) => {
    let attempt = 0;
    while (attempt < retries) {
      try {
        return await fn();
      } catch (error) {
        if (attempt < retries - 1) {
          console.log("Retrying")
          await new Promise(res => setTimeout(res, delay));
          attempt++;
        } else {
          throw error;
        }
      }
    }
  };  

  const useSignup = () => {
    const signup = async (email, password, fullName) => {
      return await callApiWithAuth({
        endpoint: EndPoint.SIGN_UP,
        body: { email, password, fullName },
        includeCredentials: true
      });
    };

    return signup;
  };

  const useSignIn = () => {
    const signIn = async (email, password) => {
      const data = await callApi({
        endpoint: EndPoint.SIGN_IN,
        body: { email, password },
      });
      updateAuthState(true);
      updateSubscriptionState(data.hasSubscription);
      return data;
    };
    return signIn;
  };

  const useGetDynamicForm = () => {
    const getDynamicForm = async (path) => {
      const options = {
        endpoint: EndPoint.DYNAMIC_FORM,
        includeCredentials: true,
      };
      if (path != null) {
        options.body = {custom: path};
      }
      return await callApiWithAuth(options);
    };
    return getDynamicForm;
  };

  const useGenerateForms = () => {
    const setGenerateForms = async (
      formData,
      selectedGovFiles,
      selectedFilesByContractName,
      selectedFiles,
      name,
      id
    ) => {
      return await callApiWithAuth({
        endpoint: EndPoint.GENERATE_FORMS,
        body: {
          formData: formData,
          selectedGovFiles: selectedGovFiles,
          selectedFilesByContractName: selectedFilesByContractName,
          fileNames: selectedFiles,
          dealName: name,
          id: id,
        },
        includeCredentials: true,
      });
    };

    return setGenerateForms;
  };

  const useListDeals = () => {
    const setListDeals = async () => {
      return await callApiWithAuth({
        endpoint: EndPoint.LIST_DEALS,
        includeCredentials: true,
      });
    };

    return setListDeals;
  };

  const useValidDealLinks = () => {
    const getValidDealLinks = async (urls_storage_paths) => {
      return await callApiWithAuth({
        endpoint: EndPoint.DEAL_LINKS,
        body: {
          storage_paths: urls_storage_paths
        },
        includeCredentials: true,
      });
    };

    return getValidDealLinks;
  };

  const useDeleteDeal = () => {
    const setDeleteDeal = async (dealId) => {
      return await callApiWithAuth({
        endpoint: EndPoint.DELETE_DEAL,
        body: {
          id: dealId
        },
        includeCredentials: true,
      });
    };

    return setDeleteDeal;
  };

  const useDuplicateDeal = () => {
    const setDuplicateDeal = async (dealId) => {
      return await callApiWithAuth({
        endpoint: EndPoint.DUPLICATE_DEAL,
        body: {
          id: dealId
        },
        includeCredentials: true,
      });
    };

    return setDuplicateDeal;
  };

  const useUpdateSubscription = () => {
    const setUpdateSubscription = async (customerId) => {
      // TODO: update subscription state for actual signin
      const apiCall = async () => await callApiWithAuth({
        endpoint: EndPoint.UPDATE_POSSIBLE_SUBSCRIPTION,
        body: { 
          id: customerId 
        },
      });
      return await retry(apiCall, 5, 2000);
    };

    return setUpdateSubscription;
  };

  const useSubmitContract = () => {
    const getSubmitContract = async (formData) => {
      return await callApiWithAuth({
        endpoint: EndPoint.SUBMIT_CONTRACT,
        body: formData,
        includeCredentials: true,
      });
    };
    return getSubmitContract;
  };

  const useScanAndExtractDoc = () => {
    const getScanAndExtractDoc = async (formData) => {
      return await callApiWithAuth({
        endpoint: EndPoint.SCAN_AND_EXTRACT_DOC,
        body: formData,
        includeCredentials: true,
      });
    };
    return getScanAndExtractDoc;
  };

  const useListContracts = () => {
    const listContracts = async () => {
      return await callApiWithAuth({
        endpoint: EndPoint.LIST_CONTRACTS,
        includeCredentials: true,
      });
    };

    return listContracts;
  };

  const useOriginalContractLink = () => {
    const originalContractLink = async (url) => {
      return await callApiWithAuth({
        endpoint: EndPoint.ORIGINAL_CONTRACT_LINK,
        body: { 
          url: url
        },
      });
    };

    return originalContractLink;
  };

  const useUserFeedback = () => {
    const userFeedback = async (feedback) => {
      return await callApiWithAuth({
        endpoint: EndPoint.USER_FEEDBACK,
        body: { 
          feedback: feedback
        },
      });
    };

    return userFeedback;
  };
  
  
  return {
    callApiWithAuth,
    useSignup,
    useSignIn,
    useGetDynamicForm,
    useGenerateForms,
    useListDeals,
    useValidDealLinks,
    useDeleteDeal,
    useDuplicateDeal,
    useUpdateSubscription,
    useScanAndExtractDoc,
    useSubmitContract,
    useListContracts,
    useOriginalContractLink,
    useUserFeedback
  };
};

export default useApi;