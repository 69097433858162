import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RadioButtonOption = ({ value, label, disabled }) => (
  <FormControlLabel value={value} control={<Radio />} label={label} disabled={disabled} />
);

export default function CustomRadioButtonsGroup({ options, label, name, defaultValue, onChange }) {
  return (
    <FormControl>
      <FormLabel id={`${name}-label`}>{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={`${name}-label`}
        name={name}
        value={defaultValue === undefined ? "" : defaultValue}
        onChange={(event) => onChange(event.target.value)}
      >
        {options.map((option, index) => (
          <RadioButtonOption key={index} {...option} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
