import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext({
  isAuthenticated: false,
  hasSubscription: false,
  updateAuthState: () => {},
  updateSubscriptionState: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);

  const updateAuthState = (authState) => {
    if (!authState) {
      updateSubscriptionState(false);
    }
    setIsAuthenticated(authState);
  };

  const updateSubscriptionState = (subscriptionState) => {
    setHasSubscription(subscriptionState);
  };

  const isFullyAuthenticated = () => isAuthenticated && hasSubscription;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        updateAuthState,
        hasSubscription,
        updateSubscriptionState,
        isFullyAuthenticated
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
