import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Select, MenuItem } from '@mui/material';

function AddTemplateDialog({
  selectedText,
  handleCancelSelection,
  handleSaveSelection,
  currentTemplate,
  setCurrentTemplate,
  templateOptions,
  customSelectionValue,
  customTemplate,
  setCustomTemplate
}) {
  const handleChange = (e) => {
    const value = e.target.value;
    // Regular expression to replace any separator with an underscore
    const validValue = value.replace(/[\s.\\/]+/g, '_');
    setCustomTemplate(validValue);
  };

  return (
    <Dialog
      open={Boolean(selectedText)}
      onClose={handleCancelSelection}
      aria-labelledby="selection-dialog-title"
    >
      <DialogTitle id="selection-dialog-title">בחר תבנית</DialogTitle>
      <DialogContent>
        <DialogContentText>{selectedText}</DialogContentText>
        <Select
          value={currentTemplate}
          onChange={(e) => setCurrentTemplate(e.target.value)}
          displayEmpty
          fullWidth
          style={{ marginTop: "10px" }}
        >
          {templateOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {currentTemplate === customSelectionValue && (
          <TextField
            value={customTemplate}
            onChange={handleChange}
            placeholder="הכנס תבנית מותאמת"
            fullWidth
            style={{ marginTop: "10px" }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelSelection} color="secondary">
          ביטול
        </Button>
        <Button onClick={handleSaveSelection} color="primary">
          שמור
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddTemplateDialog;
