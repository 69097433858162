/**
 * Represents the endpoint for the API call.
 * @enum {string}
 */
export const EndPoint = {
  SIGN_UP: '/signup',
  SIGN_IN: '/signin',
  DYNAMIC_FORM: '/dynamic-form',
  GENERATE_FORMS: '/generate-forms',
  LIST_DEALS: '/list-deals',
  DEAL_LINKS: '/deal-links',
  DELETE_DEAL: "/delete-deal",
  DUPLICATE_DEAL: "/duplicate-deal",
  UPDATE_POSSIBLE_SUBSCRIPTION: "/update-possible-subscription",
  SCAN_AND_EXTRACT_DOC: "/scan-and-extract-doc",
  SUBMIT_CONTRACT: "/submit-contract",
  LIST_CONTRACTS: "/list-contracts",
  ORIGINAL_CONTRACT_LINK: "/original-contract-link",
  USER_FEEDBACK: "/user-feedback"
};

/**
 * Calls the API with the given parameters.
 * @param {Object} params - The parameters for the API call.
 * @param {EndPoint} params.endpoint - The endpoint for the API call.
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE'} [params.method='POST'] - The HTTP method for the API call.
 * @param {Object} [params.body] - The request body for the API call.
 * @returns {Promise<any>} The response from the API.
 */
const callApi = async ({ endpoint, method = 'POST', body , includeCredentials = true}) => {
  const fetchOptions = {
    method: method,
    headers: {},
    body: null,
  };
  if (includeCredentials) {
    fetchOptions.credentials = 'include';
  }
  if (body instanceof FormData) {
    // Content-Type header will be set automatically)
    fetchOptions.body = body;
  } else {
    // Default case: assume JSON body
    fetchOptions.headers['Content-Type'] = 'application/json';
    fetchOptions.body = JSON.stringify(body);
  }
  
  try {
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + `${endpoint}`, fetchOptions);

    if (!response.ok) {
      const errorBody = await response.json();
      const error = new Error('API call was not ok');
      error.response = errorBody;
      error.status = response.status;
      console.log(error.response)
      console.log(error.status)
      throw error;
    }
    return await response.json();
    
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
};

export default callApi;