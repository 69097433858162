import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  direction: 'rtl',
  
  palette: {
    primary: {
      main: "#556cd6", // Replace with your primary color
    },
    secondary: {
      main: "#19857b", // Replace with your secondary color
    },
    action: {
      hover: "#188977", // Color when hovered
      selected: "#188977", // Color when selected
    },
    drawerText: {
        main: '#DEEDCF', // Replace with your desired color
    },
    // Add any additional colors you want to use
  },
  // You can also define typography, breakpoints, and other theme-related settings
});

export default theme;
