const predefinedPlaceholders = {
  Seller: {
    "מוכר 1": [
      "סוג_זיהוי_מוכר_1",
      "תז_מוכר_1",
      "תעודה_מזהה_מוכר_1",
      "כתובת_מוכר_1_יישוב",
      "כתובת_מוכר_1_רחוב",
      "כתובת_מוכר_1_מספר",
      "שם_משפחה_מוכר_1",
      "שם_פרטי_מוכר_1",
    ],
    "מוכר 2": [
      "סוג_זיהוי_מוכר_2",
      "תז_מוכר_2",
      "תעודה_מזהה_מוכר_2",
      "כתובת_מוכר_2_יישוב",
      "כתובת_מוכר_2_רחוב",
      "כתובת_מוכר_2_מספר",
      "שם_משפחה_מוכר_2",
      "שם_פרטי_מוכר_2",
    ],
  },
  "Property data": {
    "פרטי הנכס": ["גוש", "חלקה", "תת-חלקה"],
    "כתובת הנכס": ["יישוב", "כתובת"],
  },
  Buyer: {
    "קונה 1": [
      "סוג_זיהוי_קונה_1",
      "תז_קונה_1",
      "תעודה_מזהה_קונה_1",
      "כתובת_קונה_1_יישוב",
      "כתובת_קונה_1_רחוב",
      "כתובת_קונה_1_מספר",
      "שם_משפחה_קונה_1",
      "שם_פרטי_קונה_1",
    ],

    "קונה 2": [
      "סוג_זיהוי_קונה_2",
      "תז_קונה_2",
      "תעודה_מזהה_קונה_2",
      "כתובת_קונה_2_יישוב",
      "כתובת_קונה_2_רחוב",
      "כתובת_קונה_2_מספר",
      "שם_משפחה_קונה_2",
      "שם_פרטי_קונה_2",
    ],
  },
  DealInfo: {
    
  },
  Miscellaneous: {
    
  },
  // Add more types and their predefined categories here
};
  
  export default predefinedPlaceholders;
  