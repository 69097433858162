import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Grid, Box, TextField, Button, Checkbox, FormControlLabel, Typography, Paper, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

import useApi from './useApi';
import { useAuth } from './AuthContext';
import { initializePaddle, Paddle } from '@paddle/paddle-js';
import VersatileAlertDialog from './UI/VersatileAlertDialog';

const SignUp = () => {
  const { updateSubscriptionState } = useAuth();
  const [acceptUpdates, setAcceptUpdates] = useState(false);
  const [paddle, setPaddle] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  
  const { useSignup, useUpdateSubscription } = useApi();
  const signup = useSignup();
  const updateSubscription = useUpdateSubscription()

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    fullName: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    fullName: '',
  });
  const [versatileAlertOpen, setVersatileAlertOpen] = useState(false);

  useEffect(() => {
    console.log(process.env.REACT_APP_SUBSCRIPTION_TOKEN)
    initializePaddle(paddleOptions).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      } else {
        console.error('Paddle initialization failed.');
        setAlertMessage("נראה שהסליקת התשלומים שלנו לא עובדת כרגע, רענן את העמוד או נסו מאוחר יותר. אנחנו על זה")
        setAlertVisible(true)
      }
    });
  }, []);

  const paddleOptions = {
    environment: process.env.REACT_APP_SUBSCRIPTION_ENVIROMENT,
    token: process.env.REACT_APP_SUBSCRIPTION_TOKEN,
    eventCallback: (event) => {
      console.log(event)
      if (event.name === 'checkout.completed') {
        const id = event.data.customer.id
        console.log(id)
        if (id) {
          performUpdateSubscription(event.data.customer.id)
        } else {
          setAlertMessage("נראה שהייתה בעיה בתהליך התשלום. נסה שוב או צור איתנו קשר, אנחנו לא נושכים :)");
          setAlertVisible(true);
        }
      } else if (event.name === 'checkout.closed') {
          setAlertMessage("נראה שחלון התשלום נסגר לפני שהושלם, ע״מ להשתמש במוצר יש לשלם תחילה. זה שווה את זה :)");
          setAlertVisible(true);
      }
    },
  };

  const performUpdateSubscription = async(id) => {
    try {
      var overlay = document.querySelector('.paddle-frame.paddle-frame-overlay');
      if (overlay) {
        overlay.remove();
      }
      setLoading(true);
      const response = await updateSubscription(id)
      console.log("Subscription update successful:", response);
      updateSubscriptionState(response.hasSubscription)
    } catch(error) {
      console.error("Subscription update failed:", error);
      setAlertMessage("משהו לא הסתנכרן, נסה להתחבר במקום להירשם שוב");
      setAlertVisible(true);
    } finally {
      setLoading(false);
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const isTokenPresent = () => {
    const token = getCookie('your_token_name');
    return !!token;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const errorMsg = validateField(name, value);
    setFormErrors({ ...formErrors, [name]: errorMsg });
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const newErrors = Object.keys(formValues).reduce((errors, fieldName) => {
      errors[fieldName] = validateField(fieldName, formValues[fieldName]);
      return errors;
    }, {});    
    setFormErrors(newErrors);

    const isValid = Object.values(newErrors).every((errorMsg) => errorMsg === '');
    
    if (isValid) {
      console.log('Form is valid. Submitting...', formValues);
      performSignup()
    }
  };

  const performSignup = async() => {
    try {
        setLoading(true);
        const response = await signup(
            formValues.email, 
            formValues.password, 
            formValues.fullName)
        console.log("Signup successful:", response);
        
        presentSubscription()
        
      } catch(error) {
        // TODO:
        console.error("Sign in failed:", error);
        setVersatileAlertOpen(true)
      } finally {
        setLoading(false);
      }
  }

  const presentSubscription = () => {
    const items = [
      {
        priceId: 'pri_01hx2dcjh3pb1h0w6tk1sjz1dc',
        quantity: 1,
      },
    ];

    paddle.Checkout.open({
      items: items,
    });
  }

  const handleWarningAlertClosed = () => {
    setAlertVisible(false)

    if (isTokenPresent) {
      presentSubscription()
    }
  }

  const validateField = (name, value) => {
    let errorMsg = '';
    switch (name) {
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          errorMsg = "נראה שהאימייל שלך לא תקין";
        }
        break;
      case 'password':
        if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value)) {
          errorMsg = 'הסיסמה חייבת להיות לפחות 8 תווים, אות גדולה אחת, אות קטנה אחת ו-2 מספרים';
        }
        break;
      case 'fullName':
        if (!/\S+ \S+/.test(value)) {
          errorMsg = 'שם מלא עם רווח בין השם הפרטי למשפחה';
        }
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const onSignIn = () => {
    navigate('/signin')
  }

  const handleVersatileAlertClose = () => {
    setVersatileAlertOpen(false);
  };

  const paperStyle = {
    padding: '20px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  };

  return (
    <Container component="main" maxWidth="md">
      <VersatileAlertDialog
        open={versatileAlertOpen}
        onClose={handleVersatileAlertClose}
        onTryAgain={handleVersatileAlertClose}
      />
      {loading && (
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}

      {alertVisible && (
        <Alert 
        style={{
          position: "absolute",
          top: "15%",
          left: "32%",
        }}
        severity="warning" onClose={handleWarningAlertClosed}>
          {alertMessage}
        </Alert>
      )}

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        {/* User input on the right, wrapped in a Paper component for shadow */}
        <Grid item xs={12} md={6}>
          <Paper style={paperStyle}>
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                label="אימייל"
                type="email"
                autoComplete="email"
                value={formValues.email}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="fullName"
                name="fullName"
                label="שם מלא"
                autoComplete="name"
                value={formValues.fullName}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                error={!!formErrors.fullName}
                helperText={formErrors.fullName}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                label="סיסמה"
                type="password"
                autoComplete="new-password"
                value={formValues.password}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                error={!!formErrors.password}
                helperText={formErrors.password}
              />
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="בטח שאני מעוניין לקבל עדכונים לגבי מוצרים חדשים והצעות חדשות"
                checked={acceptUpdates}
                onChange={(e) => setAcceptUpdates(e.target.checked)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: "24px 0 16px" }}
              >
                הירשם
              </Button>
            </form>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="4vh"
              bgcolor="#f5f9fc"
            >
              <Typography variant="subtitle1">
                יש לך חשבון ?
                <Button color="primary" onClick={onSignIn}>
                  התחבר
                </Button>
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center" variant="h4" gutterBottom>
            LawLoomAI
          </Typography>
          <Typography
            sx={{
              marginTop: "-1.5vh",
            }}
            align="center"
            variant="subtitle1"
            gutterBottom
          >
            עריכת דין של המאה ה-21
          </Typography>
          <ul>
            {[
              "מערכת בינה מלאכותית ליצירת חוזים חכמים",
              "יצירת עסקאות המבוססות על חוזים ממשלתיים ואישיים",
              "סינכרון לענן",
              "בקרוב: שאילתות מתקדמות מבוססות AI על פסקי דין"
            ].map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
