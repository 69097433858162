// src/components/GenericForm.js

import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CustomRadioButtonsGroup from '../UI/CustomRadioButtonGroup';
import { useForm } from './FormContext';
import './GenericForm.css';
import OutlinedContainer from './OutlinedContainer';
import predefinedPlaceholders from './predefinedPlaceholders';

const GenericForm = () => {
  const { formData, updateFormData, currentType, formDataArray } = useForm();

  const currentTypeData = formDataArray().find(
    (typeData) => typeData.type === currentType
  );

  const handleInputChange = (fieldName, value) => {
    const updatedInputs = currentTypeData.inputs.map((input) =>
      input.placeholder === fieldName
        ? { ...input, user_input_data: value }
        : input
    );

    const updatedCurrentTypeData = {
      ...currentTypeData,
      inputs: updatedInputs,
    };

    const updatedFormData = formDataArray().map((typeData) =>
      typeData.type === currentType ? updatedCurrentTypeData : typeData
    );

    console.log(formData);
    updateFormData(updatedFormData);
  };

  const renderInputField = (input, blockIdx) => {
    switch (input.type) {
      case "TextInput":
        return (
          <TextField
            key={`${input.placeholder}_${blockIdx}`}
            label={input.label}
            variant="filled"
            value={input.user_input_data || ""}
            onChange={(e) =>
              handleInputChange(input.placeholder, e.target.value)
            }
            sx={{
              width: "300px",
              marginTop: "16px",
            }}
          />
        );
      case "RadioBoxView":
        return (
          <CustomRadioButtonsGroup
            key={`${input.placeholder}_${blockIdx}`}
            label={input.label}
            name={input.placeholder} // Use unique name per input
            defaultValue={input.user_input_data}
            options={input.labels.map((label) => ({
              value: label,
              label: label,
            }))}
            onChange={(value) => handleInputChange(input.placeholder, value)}
          />
        );
      default:
        return null;
    }
  };

  if (!currentTypeData || !currentTypeData.inputs) {
    return <div>Loading...</div>;
  }

  // Method to categorize inputs
  const categorizeInputs = (inputs) => {
    // Get predefined placeholders for the current type
    const typePredefined = predefinedPlaceholders[currentType] || {};

    // Initialize categories
    const categorizedInputs = Object.keys(typePredefined).map((category) => ({
      category,
      placeholders: typePredefined[category],
      inputs: [],
    }));

    // Initialize extra inputs
    const extraInputs = [];

    // Categorize inputs with substring matching
    inputs.forEach((input) => {
      let found = false;
      for (let category of categorizedInputs) {
        if (
          category.placeholders.some(
            (predefinedPlaceholder) =>
              predefinedPlaceholder.includes(input.placeholder) ||
              input.placeholder.includes(predefinedPlaceholder)
          )
        ) {
          category.inputs.push(input);
          found = true;
          break; // Assign to the first matching category
        }
      }
      if (!found) {
        extraInputs.push(input);
      }
    });

    return { categorizedInputs, extraInputs };
  };

  // Method to sort inputs within each category using exact match priority and then substring matching
  const sortCategoryInputs = (categorizedInputs) => {
    categorizedInputs.forEach((category) => {
      const customOrder = category.placeholders;

      category.inputs.sort((a, b) => {
        // Function to find index with exact match priority, then substring match
        const findIndex = (placeholder) => {
          // First, try to find an exact match
          const exactMatchIndex = customOrder.indexOf(placeholder);
          if (exactMatchIndex !== -1) return exactMatchIndex;

          // If no exact match, find the first substring match
          const substringMatchIndex = customOrder.findIndex(
            (orderPlaceholder) =>
              orderPlaceholder.includes(placeholder) ||
              placeholder.includes(orderPlaceholder)
          );

          return substringMatchIndex === -1
            ? Number.MAX_VALUE
            : substringMatchIndex;
        };

        const indexA = findIndex(a.placeholder);
        const indexB = findIndex(b.placeholder);

        // Sort based on exact or substring match indices
        return indexA - indexB;
      });
    });
  };

  // Categorize and sort inputs
  const { categorizedInputs, extraInputs } = categorizeInputs(
    currentTypeData.inputs
  );
  sortCategoryInputs(categorizedInputs);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        marginTop: "16px",
      }}
    >
      {/* Render each category as an OutlinedContainer */}
      {categorizedInputs.map(
        (category, idx) =>
          category.inputs.length > 0 && (
            <OutlinedContainer
              key={`predefined-${idx}`}
              // title={`${category.category}`}
            >
              {category.inputs.map((input, blockIdx) => (
                <div key={blockIdx}>{renderInputField(input, blockIdx)}</div>
              ))}
            </OutlinedContainer>
          )
      )}

      {/* Render Extra Inputs */}
      {extraInputs.length > 0 && (
        <OutlinedContainer /*title={`${currentType}`}*/>
          {extraInputs.map((input, blockIdx) => (
            <div key={blockIdx}>{renderInputField(input, blockIdx)}</div>
          ))}
        </OutlinedContainer>
      )}
    </Stack>
  );
};

export default GenericForm;
