import React, { useState, useEffect } from 'react';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Modal, Box, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import DocxViewer from './DocxViewer';
import LoaderComponent, { LoadingState } from '../Common/LoaderComponent';
import { useContracts } from './ContractsContext';

import contractImageOne from '../Assets/Images/contractImageOne.jpg';
import contractImageTwo from '../Assets/Images/contractImageTwo.jpg';
import ErrorAlert from '../UI/ErrorAlert';

const ContractsManagerViewer = () => {
    const { contracts, fetchContracts } = useContracts();
    const [selectedContract, setSelectedContract] = useState(null);
    const [openDocxViewer, setOpenDocxViewer] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    useEffect(() => {
        handleFetchContracts();
    }, []);

    const handleFetchContracts = async () => {
        setLoading(true);
        try {
            await fetchContracts();
        } catch (error) {
            console.error("Failed to fetch contracts:", error);
            setErrorDialogOpen(true)
        } finally {
            setLoading(false);
        }
    };

    const onRetryFetchData = async () => {
        setErrorDialogOpen(false);
        await handleFetchContracts()
    }

    const handleCardClick = (contract) => {
        setSelectedContract(contract);
        setOpenDocxViewer(true);
    };

    const handleDocxViewerClose = (updated) => {
        setOpenDocxViewer(false);
        setSelectedContract(null);
        if (updated) {
            fetchContracts(true);
        }
    };

    const renderEmptyState = () => (
        <div style={{ textAlign: 'center' }}>
            <Typography variant="h5">צור את החוזה הראשון שלך המבוסס סריקת AI מתקדמת</Typography>
            <Button variant="contained" color="primary" onClick={() => setOpenDocxViewer(true)}>
                אוקי בוא נתחיל
            </Button>
        </div>
    );

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };
        return new Date(dateString).toLocaleDateString('he-IL', options);
    };

    const onClose = () => {
      setErrorDialogOpen(false);
    };

    // Add a dummy object to the contracts array for the "Add New" card
    const contractsWithAddNew = [...contracts, { isAddNewCard: true }];

    return (
      <div>
        <ErrorAlert
          open={errorDialogOpen}
          onClose={onClose}
          onRetry={onRetryFetchData}
        />
        <LoaderComponent
          loading={loading}
          loadingState={LoadingState.GENERAL}
        />
        {!loading &&
          (contracts.length === 0 ? (
            renderEmptyState()
          ) : (
            <Grid container spacing={3}>
              {contractsWithAddNew.map((contract, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  {contract.isAddNewCard ? (
                    <Card>
                      <CardActionArea onClick={() => setOpenDocxViewer(true)}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          height="235px"
                          bgcolor="white"
                        >
                          <AddIcon style={{ fontSize: 50 }} />
                          <Typography variant="h6">סרוק חוזה נוסף</Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  ) : (
                    <Card
                      sx={{
                        borderRadius: '10px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                        position: 'relative', // For overlay positioning
                        '&:hover': {
                          transform: 'translateY(-10px) scale(1.02)',
                          boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
                        },
                        '&:active': {
                          transform: 'translateY(-5px) scale(1.01)',
                          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15)',
                        },
                      }}
                    >
                      <CardActionArea onClick={() => handleCardClick(contract)}>
                        <CardMedia
                          component="img"
                          alt="Contract"
                          height="140"
                          image={
                            index % 2 === 0
                              ? contractImageTwo
                              : contractImageOne
                          }
                        />
                        <CardContent>
                          <Typography variant="h6">
                            <strong>שם החוזה:</strong> {contract.name}
                          </Typography>
                          <Typography variant="h6">
                            <strong>תאריך עדכון:</strong>{" "}
                            {formatDate(contract.date)}
                          </Typography>
                        </CardContent>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            opacity: 0,
                            transition: "opacity 0.3s ease",
                            backgroundColor: "rgba(0, 0, 0, 0.25)",
                            "&:hover": {
                              opacity: 1,
                            },
                          }}
                        >
                          <EditIcon
                            sx={{
                              fontSize: 50,
                              color: "white",
                            }}
                          />
                        </Box>
                      </CardActionArea>
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
          ))}
        <Modal
          open={openDocxViewer}
          onClose={() => handleDocxViewerClose(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              height: "95%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 1,
              overflowY: "auto",
            }}
          >
            <IconButton
              onClick={() => handleDocxViewerClose(false)}
              sx={{ position: "relative", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <DocxViewer
              contract={selectedContract}
              onClose={handleDocxViewerClose}
            />
          </Box>
        </Modal>
      </div>
    );
};

export default ContractsManagerViewer;
