import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import useApi from '../useApi';

const FeedbackButton = () => {
  const [open, setOpen] = useState(false); 
  const [feedback, setFeedback] = useState(''); 
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { useUserFeedback } = useApi();
  const userFeedback = useUserFeedback();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (feedback.trim() === '') {
      setSnackbar({ open: true, message: 'הפידבק אינו יכול להיות ריק.', severity: 'warning' });
      return;
    }

    try {
      const response = await userFeedback(feedback)

      if (response.status) {
        setSnackbar({ open: true, message: 'הפידבק נשלח בהצלחה!', severity: 'success' });
        setFeedback('');
        setOpen(false);
      } else {
        throw new Error('שגיאה בשליחת הפידבק.');
      }
    } catch (error) {
      console.error(error);
      setSnackbar({ open: true, message: 'אירעה שגיאה. אנא נסה שוב מאוחר יותר.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      {/* Fixed Position Button */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 54,
          'right': 54,
          zIndex: (theme) => theme.zIndex.tooltip + 1, // Ensure it's on top
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" sx={{ mb: 1, color: 'text.primary', textAlign: 'center' }}>
          שלחו פידבק
        </Typography>
        <IconButton
          color="primary"
          onClick={handleOpen}
          sx={{
            backgroundColor: 'background.paper',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'primary.light',
            },
          }}
        >
          <FeedbackIcon />
        </IconButton>
        <Typography variant="body2" sx={{ mb: 1, mt: 1, color: 'text.primary', textAlign: 'center' }}>
          על כל דבר קטן 
        </Typography>
        <Typography variant="body2" sx={{ mt: -1, color: 'text.primary', textAlign: 'center' }}>
        🙂
        </Typography>
      </Box>

      {/* Feedback Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>אנחנו רוצים לדעת הכל 🤖</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="כיתבו בדיוק איזו פעולה עשיתם שהשתבשה או אולי איזה פיצ׳ר חסר לכם"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ביטול</Button>
          <Button variant="contained" onClick={handleSubmit}>
            שלח
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FeedbackButton;
