import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from './Forms/FormContext';
import { useContracts } from './Docx/ContractsContext';
import { Grid, Typography, Checkbox, FormControlLabel, Box } from '@mui/material';
import VersatileAlertDialog from './UI/VersatileAlertDialog';

const FileSelectionTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const {
    userFiles,
    updateUserFiles,
    selectedFiles,
    updateSelectedFiles,
    govFileNames,
    setGovFileNames,
  } = useForm();
  const [versatileAlertOpen, setVersatileAlertOpen] = useState(false);

  const { contracts, govContracts } = useContracts();

  const handleFileChange = (file) => {
    updateSelectedFiles((prevSelected) =>
      prevSelected.includes(file)
        ? prevSelected.filter((f) => f !== file)
        : [...prevSelected, file]
    );
  };

  const handleSelectAll = (files) => {
    updateSelectedFiles((prevSelected) => {
      const allSelected = files.every((file) => prevSelected.includes(file));
      return allSelected
        ? prevSelected.filter((file) => !files.includes(file))
        : [...new Set([...prevSelected, ...files])];
    });
  };

  useEffect(() => {
    const fetchAndProcessFiles = () => {
      const decodedFiles = fetchAvailableFiles();
      const editSelectedFiles = location.state
        ? location.state.deal.files
        : null;

      if (decodedFiles && editSelectedFiles) {
        const arr = decodedFiles.filter(file =>
          editSelectedFiles.some(editFile => editFile.includes(file))
        );
        updateSelectedFiles(arr);
      }
      setIsLoading(false);
    };
    fetchAndProcessFiles();
  }, []);

  const fetchAvailableFiles = () => {
    try {
      const files = contracts.map((contract) => contract.name);
      const govFiles = govContracts.map((contract) => contract.name);
      console.log("AvailableFiles successful:", files);
      console.log("govFiles successful:", govFiles);
      // Presentation only code - actual metadata files in ContractContext
      updateUserFiles(files);
      setGovFileNames(govFiles);
      return [...files, ...govFiles];
    } catch (error) {
      console.error("AvailableFiles failed:", error);
      setVersatileAlertOpen(true);
    }
  };

  const handleVersatileAlertClose = () => {
    setVersatileAlertOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">חוזים קיימים</Typography>
          <Box mb={2} mt={1} style={{ borderBottom: '0.5px solid #ccc', paddingBottom: '8px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => handleSelectAll(userFiles)}
                  checked={userFiles.every(file => selectedFiles.includes(file))}
                  sx={{
                    color: 'darkblue',
                    '&.Mui-checked': { color: 'darkviolet' },
                  }}
                />
              }
              label={<Typography variant="subtitle1" sx={{ fontWeight: '500' }}>סמן הכל</Typography>}
            />
          </Box>
          {userFiles.map((file) => (
            <FormControlLabel
              key={file}
              control={
                <Checkbox
                  checked={selectedFiles.includes(file)}
                  onChange={() => handleFileChange(file)}
                />
              }
              label={file}
            />
          ))}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">מסמכים נלווים</Typography>
          <Box mb={2} mt={1} style={{ borderBottom: '0.5px solid #ccc', paddingBottom: '8px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => handleSelectAll(govFileNames)}
                  checked={govFileNames.every(file => selectedFiles.includes(file))}
                  sx={{
                    color: 'darkblue',
                    '&.Mui-checked': { color: 'darkviolet' },
                  }}
                />
              }
              label={<Typography variant="subtitle1" sx={{fontWeight: '500' }}>סמן הכל</Typography>}
            />
          </Box>
          {govFileNames.map((file) => (
            <FormControlLabel
              key={file}
              control={
                <Checkbox
                  checked={selectedFiles.includes(file)}
                  onChange={() => handleFileChange(file)}
                />
              }
              label={file}
            />
          ))}
        </Grid>
      </Grid>

      <VersatileAlertDialog
        open={versatileAlertOpen}
        onClose={handleVersatileAlertClose}
        onTryAgain={handleVersatileAlertClose}
      />
    </React.Fragment>
  );
};

export default FileSelectionTab;
