import React, { createContext, useContext, useState } from 'react';

import useApi from './useApi';

const DealsContext = createContext();

export const DealsProvider = ({ children }) => {
  const { useListDeals } = useApi();
  const getListDeals = useListDeals();

  const [deals, setDeals] = useState([]);

  // TODO: Add functionality to manage deals
  // Adding a new deal, update a deal, delete a deal, refresh list

  const refreshDealsData = async () => {
    const data = await getListDeals();
    setDeals(data)
  };

  const fetchDeals = async (shouldForceUpdate = false) => {
    if (deals.length === 0 || shouldForceUpdate === true) {
        await refreshDealsData()
    }
};

  return (
    <DealsContext.Provider value={{ deals, setDeals, fetchDeals, refreshDealsData }}>
      {children}
    </DealsContext.Provider>
  );
};


export const useDeals = () => useContext(DealsContext);