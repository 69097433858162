import React , {useEffect} from 'react';
import { useForm } from './FormContext';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

// Manages the currentType
const FormSelector = () => {
    const { formData, formDataArray, currentType, updateCurrentType } = useForm();
    // console.log('FormSelector formData isArray:', Array.isArray(formData));

    useEffect(() => {
      if (!currentType && formDataArray().length > 0) {
        updateCurrentType(formDataArray()[0].type);
      }
  }, [currentType, formDataArray, updateCurrentType]);

    const handleButtonClick = (type) => {
      updateCurrentType(type);
    };
  
    if (!formData) return <div>Loading...</div>;
  
    return (
      <ButtonGroup variant="text" sx={{
        marginTop: '16px',
        }}>
        {formDataArray().map(typeData => (
          <Button
            key={typeData.type}
            onClick={() => handleButtonClick(typeData.type)}
            variant={currentType === typeData.type ? 'contained' : 'text'}
          >
            {typeData.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  };  

export default FormSelector;
