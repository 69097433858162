import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Fab from "@mui/material/Fab";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import DealsViewModel from "./DealsViewModel";
import { useDeals } from "./DealsContext";
import useApi from "./useApi";
import ErrorAlert from "./UI/ErrorAlert";
import VersatileAlertDialog from "./UI/VersatileAlertDialog";

const DealsView = () => {
  const {
    downloadZipFile,
    fetchDealsData,
    performDealDeletion,
    performDealDuplication,
  } = DealsViewModel();
  const { deals, setDeals } = useDeals();
  const { useGetDynamicForm } = useApi();
  const getDynamicForm = useGetDynamicForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [versatileAlertOpen, setVersatileAlertOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await fetchDealsData();
      setErrorDialogOpen(false);
    } catch (error) {
      console.error("getListDeals failed:", error);
      setErrorDialogOpen(true); 
    } finally {
      setIsLoading(false);
    }
  };

  const onRetryFetchData = async () => {
    setErrorDialogOpen(false);
    setIsLoading(true);
    await fetchData();
  };

  const onClose = () => {
    setErrorDialogOpen(false);
  };

  const parseDateString = (dateString) => {
    // Match the date string with format 'YYYY-DD-MMTHH-MM-SS'
    const match = dateString.match(
      /(\d{4})-(\d{2})-(\d{2})T(\d{2})-(\d{2})-(\d{2})/
    );

    if (match) {
      const [_, year, day, month, hour, minute, second] = match;
      // Rearrange to 'YYYY-MM-DDTHH:MM:SS' for proper parsing
      const formattedString = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
      return new Date(formattedString);
    } else {
      return null;
    }
  };

  const transformedDeals = deals.map((deal) => {
    const date = parseDateString(deal.time);
    return {
      ...deal,
      filesCount: deal.files.length,
      formattedTime: date
        ? date.toLocaleString("he-IL", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })
        : deal.time,
      originalTime: date ? date.getTime() : 0,
    };
  });

  const columns = [
    { field: "deal_name", headerName: "שם עיסקה", flex: 0.3 },
    { field: "filesCount", headerName: "מס׳ קבצים", type: "number" },
    {
      field: "formattedTime",
      headerName: "שעת יצירה",
      flex: 0.4,
      sortComparator: (v1, v2, param1, param2) =>
        param2.api.getCellValue(param2.id, "originalTime") -
        param1.api.getCellValue(param1.id, "originalTime"),
    },
    {
      field: "download",
      headerName: "הורדה",
      sortable: false,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() =>
            downloadFiles(params.row.storage_paths, params.row.deal_name)
          }
        >
          <CloudDownloadIcon />
        </Button>
      ),
    },
    {
      field: "edit",
      headerName: "עריכה",
      sortable: false,
      renderCell: (params) => (
        <Button color="primary" onClick={() => editDeal(params.row)}>
          <EditNoteIcon />
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "מחיקה",
      sortable: false,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => deleteDeal(params.id, params.row.id)}
        >
          <DeleteIcon />
        </Button>
      ),
    },
    {
      field: "duplicate",
      headerName: "שיכפול",
      sortable: false,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => duplicateDeal(params.id, params.row.id)}
        >
          <ContentCopyIcon />
        </Button>
      ),
    },
  ];

  const downloadFiles = async (storagePaths, dealName) => {
    setIsLoading(true);
    try {
      const content = await downloadZipFile(storagePaths);
      saveAs(content, dealName + ".zip");
    } catch (error) {
      console.error("Error downloading files:", error);
      setVersatileAlertOpen(true)
    } finally {
      setIsLoading(false);
    }
  };

  const editDeal = async (dealMetadata) => {
    setIsLoading(true);
    try {
      const json = await getDynamicForm(dealMetadata.json_path);
      dealMetadata.json = json;
      navigate("/dashboard/new-deal", {
        state: { deal: dealMetadata },
      });
    } catch (error) {
      console.error("Error editDeal:", error);
      setVersatileAlertOpen(true)
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDeal = async (rowId, dealId) => {
    setIsLoading(true);

    try {
      await performDealDeletion(dealId);
      setDeals(deals.filter((row) => row.id !== rowId)); // Corrected to use 'deals' instead of 'transformedDeals'
    } catch (error) {
      console.error("Error deleteDeal:", error);
      setVersatileAlertOpen(true)
    } finally {
      setIsLoading(false);
    }
  };

  const duplicateDeal = async (dealId) => {
    setIsLoading(true);

    try {
      const duplicatedDeal = await performDealDuplication(dealId);
      setDeals([...deals, duplicatedDeal.object]);
    } catch (error) {
      console.error("Error duplicateDeal:", error);
      setVersatileAlertOpen(true)
    } finally {
      setIsLoading(false);
    }
  };

  const handleVersatileAlertClose = () => {
    setVersatileAlertOpen(false);
  };

  const handleTryAgain = () => {
    console.log('Trying again...');
    setVersatileAlertOpen(false);
    // TODO: Additional actions, like re-fetching data
  };

  // MARK: Rendering functions
  const renderLoadingIndicator = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "400px",
      }}
    >
      <CircularProgress />
    </Box>
  );

  const renderEmptyState = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "400px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        בואו ניצור את העסקה הראשונה
      </Typography>
      <Tooltip title="הוסף עיסקה חדשה" aria-label="add a new deal">
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => navigate("/dashboard/new-deal")}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );

  const renderDataGrid = () => (
    <div style={{ position: "relative" }}>
      {/* Loading Overlay when data is present and loading */}
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {/* Main Content */}
      <div style={{ height: 400, width: "85%" }}>
        <DataGrid
          rows={transformedDeals}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.id}
          sortModel={[
            {
              field: "formattedTime",
              sort: "asc",
            },
          ]}
          autoHeight
          disableExtendRowFullWidth
        />
      </div>
      <Tooltip title="הוסף עיסקה חדשה" aria-label="add a new deal">
        <Fab
          color="primary"
          aria-label="add"
          style={{ position: "sticky", marginTop: 19 }}
          onClick={() => navigate("/dashboard/new-deal")}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );

  // Determine what content to render based on state
  let content;

  if (isLoading && deals.length === 0) {
    // Initial loading state
    content = renderLoadingIndicator();
  } else if (!isLoading && deals.length === 0) {
    // No data and not loading
    content = renderEmptyState();
  } else if (deals.length > 0) {
    // Data is present
    content = renderDataGrid();
  }

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {/* Error Dialog */}
      <ErrorAlert
        open={errorDialogOpen}
        onClose={onClose}
        onRetry={onRetryFetchData}
      />
      <VersatileAlertDialog
        open={versatileAlertOpen}
        onClose={handleVersatileAlertClose}
        onTryAgain={handleTryAgain}
      />
      {content}
    </Box>
  );
};

export default DealsView;
