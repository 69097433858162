import JSZip from 'jszip';
import useApi from './useApi';
import { useDeals } from './DealsContext';

const DealsViewModel = () => {
    const { fetchDeals } = useDeals();
    const { useValidDealLinks, useDeleteDeal, useDuplicateDeal } = useApi();
    const getValidDealLinks = useValidDealLinks();
    const setDeleteDeal = useDeleteDeal();
    const setDuplicateDeal = useDuplicateDeal();

    const fetchDealsData = async () => {
        await fetchDeals()
    };

    const performDealDeletion = async (dealId) => {
        await setDeleteDeal(dealId)
    };

    const performDealDuplication = async (dealId) => {
        return await setDuplicateDeal(dealId)
    };
    
    const downloadZipFile = async (urls) => {
        try {
            const validUrls = await getValidDealLinks(urls);

            const zip = new JSZip();

            for (let i = 0; i < validUrls.length; i++) {
                const response = await fetch(validUrls[i]);
                const blob = await response.blob();
                console.log(blob.size); 
                const fileName = decodeURIComponent(
                  validUrls[i]
                    .substring(validUrls[i].lastIndexOf("/") + 1)
                    .split("?")[0]
                );
                console.log(fileName);
                zip.file(fileName, blob);
            }
            const content = await zip.generateAsync({ type: 'blob' });
            return content;
        } catch(error) {
            console.error("downloadZipFile failed:", error);
            throw error
        }
    };

    return { downloadZipFile, fetchDealsData, performDealDeletion, performDealDuplication }; 
};

export default DealsViewModel;
