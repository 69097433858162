// NavigationContext.js
import React, { createContext, useContext, useState } from 'react';

export const ConditionalNavigationContext = createContext(null);

export const ConditionalNavigationProvider = ({ children }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);

  const setUnsavedChanges = (unsaved) => {
    setHasUnsavedChanges(unsaved);
  };

  const confirmLeave = (callback) => {
    setIsDialogOpen(true);
    // Saves a closure (method) in a state
    setConfirmCallback(() => callback);
  };

  const handleConfirm = () => {
    if (confirmCallback) {
      confirmCallback();
    }
    setIsDialogOpen(false);
    setHasUnsavedChanges(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setConfirmCallback(null);
  };

  return (
    <ConditionalNavigationContext.Provider
      value={{
        hasUnsavedChanges,
        setUnsavedChanges,
        confirmLeave,
        isDialogOpen,
        handleConfirm,
        handleCancel,
      }}
    >
      {children}
    </ConditionalNavigationContext.Provider>
  );
};

export const useConditionalNavigation = () => useContext(ConditionalNavigationContext);
